export default {
  methods: {
    getRowCount(text) {
      if (!text) return 1;
      return text.split('\n').length;
    },
    getTextParsed(text) {
      if (!text) return '';
      let textList = text.split('\n');
      if (textList.length === 1) return text;
      let result = '';
      textList.forEach(txt => {
        result += txt + '<br/>'
      });
      return result;
    },
  }
}