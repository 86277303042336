<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  methods: {
    checkHour() {
      this.currentValue = this.formatHourFromTextInput();
    },
    formatHourFromTextInput() {
      let input = String(this.currentValue);
      let newValue = "";
      let h = input.match(/^\d{1,2}/g);
      if (h === null || h[0] < 0 || h[0] > 23) {
        return null;
      } else {
        if (h[0].length === 2 && h[0].startsWith("0")) {
            h = h[0].slice(-1);
        }
        let m = input.match(/^\d{1,2}[hH: ]?\d{2}$/g);
        if (m !== null) {
          m = m[0].slice(-2);
          if (m < 0 || m > 59) {
              m = "00";
          }
        } else {
            m = "00"
        }
        newValue = h[0]+ ":" + m;
      }
      return newValue;
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">

    <AkLabel :required="isRequired">{{ this.label }}</AkLabel>
    <input v-model="currentValue" size="5" maxlength="5" @focusout="checkHour()"
        :disabled="isDisabled" :class="{'p-invalid':isInvalid && alreadySubmitted}"
        :placeholder="getPlaceholder" class="form-control" />
    <small
        v-if="isValidatorRequired"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>

  </div>
</template>