export default {
    data() {
        return {
            linkTypeConst: [
                {label: this.$t('link_type.start'),value: 'START'},
                {label: this.$t('link_type.intermediary'),value: 'INTERMEDIARY'},
                {label: this.$t('link_type.end'),value: 'END'},
            ],
        }
    },
    methods: {
        displayForLinkTypeConst(value) {
            let result = this.linkTypeConst.find(el => el.value === value);
            return result? result.label : '';
        },
    },
}