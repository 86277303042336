<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    mode: {
      type: String,
      required: false,
      default: "decimal"
    },
    suffix: {
      type: String,
      required: false,
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    minFractionDigits: {
      type: Number,
      required: false,
      default: null
    },
    maxFractionDigits: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },

  },
  methods: {},
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    },
    isBoundaryRequired() {
      return this.validator !== undefined && this.validator.betweenValue !== undefined && this.validator.betweenValue.$invalid && this.alreadySubmitted;
    },
    isMinValueRequired() {
      return this.validator !== undefined && this.validator.minValue !== undefined && this.validator.minValue.$invalid && this.alreadySubmitted;
    },
    getCurrency() {
      return this.mode === "currency" ? "EUR" : undefined;
    },
    useGrouping() {
      return this.mode !== "decimal";
    }
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel :required="isRequired">{{ this.label }}</AkLabel>
    <InputNumber v-model="currentValue" :class="{'p-invalid':isInvalid && alreadySubmitted, 'disabled':isDisabled}" :currency="getCurrency" :min="min" :max="max"
                 :minFractionDigits="this.minFractionDigits" :maxFractionDigits="this.maxFractionDigits"
                 :disabled="isDisabled" :mode="this.mode" :placeholder="getPlaceholder" :suffix="this.suffix" :use-grouping="useGrouping"
                 class="form-control" type="text"/>
    <small
        v-if="isValidatorRequired"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>

    <small
        v-if="isBoundaryRequired"
        class="p-error">{{
        $t("min_max_value", [this.label, this.validator.betweenValue.$params.min, this.validator.betweenValue.$params.max])
      }}</small>

    <small
        v-if="isMinValueRequired"
        class="p-error">{{
        $t("min_value", [this.label, this.validator.minValue.$params.min,])
      }}</small>

  </div>
</template>