<script>
export default {
  name: "AkButton",
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => {}
    },
    className: {
      type: String,
      required: false,
      default: "btn btn-xs btn-inverse-primary"
    },
    loading: {
      type: Boolean,
      required: false,
      default: null
    }
  },
}
</script>

<template>
  <button :class="className"
          @click="onClick" :disabled="loading !== null && loading">
    <slot v-if="!(loading !== null && loading)"/>
    <span v-else>
      <i class="fa fa-circle-notch fa-spin"/>
    </span>
  </button>
</template>

<style scoped>

</style>