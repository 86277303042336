export default {
    data() {
        return {
            trafficConditionConst: [
                {label: this.$t('traffic_condition.C1'),value: 'C1'},
                {label: this.$t('traffic_condition.C2'),value: 'C2'},
                {label: this.$t('traffic_condition.C3'),value: 'C3'},
                {label: this.$t('traffic_condition.C4'),value: 'C4'},
                {label: this.$t('undefined_short'), value: null},
            ],
        }
    },
    methods: {
        displayForTrafficConditionConst(value) {
            let result = this.trafficConditionConst.find(el => el.value === value);
            return result? result.label : '';
      }
    }
}