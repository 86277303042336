export default {
  data() {
    return {
      typeVhConst: [
        {label: this.$t('vh_type.highway'), value: "HIGHWAY"},
        {label: this.$t('vh_type.national'), value: "NATIONAL"}
      ]
    }
  },
  methods: {
    displayForTypeVhConst(value) {
      let result = this.typeVhConst.find(el => el.value === value);
      return result ? result.label : '';
    }
  }
}