import moment from "moment/moment";

export default {
    methods: {

        // LISTS
        
        swap(list, from, to) {
            if (list.length < from || list.length < to) return;
            if (from < 0 || to < 0) return;
            list.splice(from, 1, list.splice(to, 1, list[from])[0]);
        },
        findIndexForId(list, id) {
            for (let i = 0; i < list.length; i++)
                if (list[i].id === id) return i;
            return -1;
        },
        replaceInListById(list, data) {
            let index = this.findIndexForId(list, data.id);
            list[index] = data;
        },
        removeInListById(list, id) {
            let index = this.findIndexForId(list, id);
            list.splice(index, 1);
        },
        increaseInListById(list, id) {
            let index = this.findIndexForId(list, id);
            if (index < list.length - 1) this.swap(list, index, index + 1);
        },
        decreaseInListById(list, id) {
            let index = this.findIndexForId(list, id);
            if (index > 0) this.swap(list, index, index - 1);
        },
        setListSize(list, size) {
            while (list.length < size) {list.push({});}
            while (list.length > size) {list.pop();}
        },
        insertFirst(list, item) {
            list.splice(0, 0, item);
            return list;
        },

        // DATES

        displayDateTime(date) {
            if (!date) return '';
            return moment(date).format("DD/MM/YYYY HH:mm")
        },
        displayTime(date) {
            if (!date) return '';
            return moment(date).format("HH:mm")
        },
        displayDate(date) {
            if (!date) return '';
            return moment(date).format("DD/MM/YYYY")
        },
        displayDateWith(date, format) {
            if (!date) return '';
            return moment(date).format(format)
        },
        displayDateWithPrefix(date, format, prefix) {
            if (!date) return '';
            return this.$t(prefix) + " " + moment(date).format(format)
        },
        durationFromNow(date) {
            return moment(date).fromNow(false);
        },
        durationFromNowPrefix(date, prefix) {
            return this.$t(prefix) + " " + moment(date).fromNow(true);
        },
        createDateAsUTC(date) {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
        },
        convertDateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        
        // NUMBER
        
        checkBetween(value, min, max) {
          if(value < min) return min;
          if(value > max) return max;
          return value;
        },
        
        // DEBUG

        sleep(milliseconds) {
            const date = Date.now();
            let currentDate = null;
            do {currentDate = Date.now();}
            while (currentDate - date < milliseconds);
        },
        
        isLocalPlatform() {
            return this.$store.state.userInformation.platform === 'local';
        },
    }
}