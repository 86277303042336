export default {
    data() {
        return {
            roadwayStateConst: [
                {label: this.$t('roadway_state.dry'),value: 'DRY'},
                {label: this.$t('roadway_state.humid'),value: 'HUMID'},
                {label: this.$t('roadway_state.wet'),value: 'WET'},
                {label: this.$t('roadway_state.dripping'),value: 'DRIPPING'},
                {label: this.$t('undefined_short'), value: null},
            ],
        }
    },
    methods: {
        displayForRoadwayStateConst(value) {
            let result = this.roadwayStateConst.find(el => el.value === value);
            return result? result.label : '';
      }
    }
}