import Api from "@/services/api";
import moment from 'moment';

export default {
    findAllByLinkSheetId(linkSheetId) {
        return Api().get("/linkrows/bylinksheetid/" + linkSheetId).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllYears() {
        return Api().get("/linkrows/years/").then(res => res.data);
    },
    findById(id) {
        return Api().get("/linkrow/" + id).then(res => this.formatRes(res.data));
    },
    findAll() {
        return Api().get("/linkrows").then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    create(linkRow) {
        return Api().post("/linkrow", linkRow).then(res => this.formatRes(res.data));
    },
    update(linkRow) {
        return Api().put("/linkrow/" + linkRow.id, linkRow).then(res => this.formatRes(res.data));
    },
    delete(linkRow) {
        return Api().delete("/linkrow/" + linkRow.id).then(() => linkRow);
    },
    formatRes(e) {
        if (e.alertHour) e.alertHour = new Date(e.alertHour);
        if (e.startHour) e.startHour = new Date(e.startHour);
        if (e.endHour) e.endHour = new Date(e.endHour);
        e.alertHour = formatHour(e.alertHour);
        e.startHour = formatHour(e.startHour);
        e.endHour = formatHour(e.endHour);
        return e;
    },
    findAllForLinkSheetIds(ids) {
        return Api().post("/linkrows/bylinksheetids", ids).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    }
}

function formatHour(date) {
    return moment(date).isValid() ? moment(date).format("HH:mm") : "";
}