export default {
    data() {
        return {
            snowStateConst: [
                {label: this.$t('snow_state.dry'),value: 'DRY'},
                {label: this.$t('snow_state.humid'),value: 'HUMID'},
                {label: this.$t('snow_state.wet'),value: 'WET'},
                {label: this.$t('undefined_short'), value: null},
            ],
        }
    },
    methods: {
        displayForSnowStateConst(value) {
            let result = this.snowStateConst.find(el => el.value === value);
            return result? result.label : '';
      }
    }
}