<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, requiredIf} from '@vuelidate/validators'

/* COMPOSANTS */
import AkTime from "@components/input/AkTime";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkCheckbox from "@components/input/AkCheckbox";
import AkInputNumber from "@components/input/AkInputNumber";
import AkLabel from "@components/general/AkLabel";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import Panel from 'primevue/panel';
import Menu from 'primevue/menu';

/* MIXINS */
import randomRef from "@mixins/randomRef";
import trafficConditionConst from "@mixins/const/trafficConditionConst";
import roadwayStateConst from "@mixins/const/roadwayStateConst";
import snowStateConst from "@mixins/const/snowStateConst";
import multiLineMixin from "@mixins/multiLineMixin";

export default {
  components: {AkTime, AkInputTextArea, AkCheckbox, AkInputNumber, AkLabel, AkCheckboxButton, Panel, Menu},
  mixins: [randomRef, trafficConditionConst, roadwayStateConst, snowStateConst, multiLineMixin],
  emits: ['copyRow, pasteRow'],
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      data: [],
      items: [
        {
          label: this.$t('options'),
          items: [
            {
              label: this.$t('copy'),
              icon: 'pi pi-copy',
              command: () => { this.copy() },
            },
            {
              label: this.$t('paste'),
              icon: 'pi pi-clone',
              command: () => { this.paste() },
            }
          ]
        }
      ]
    }
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled : {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    if (this.disabled) {
      this.currentValue.obs1 = this.getTextParsed(this.currentValue.obs1);
      this.currentValue.obs2 = this.getTextParsed(this.currentValue.obs2);
      this.currentValue.obs3 = this.getTextParsed(this.currentValue.obs3);
    }
  },
  validations() {
    return {
      row: {
        roadwayState: {},
        snowState: {},
        airTemp: {},
        groundTemp: {},
        humidity: {},
        freezingHumidity: {},
        groundFrost: {},
        freezingFog: {},
        rainOnFrozenGround: {},
        rainOnSurfusion: {},
        obs1: {maxLength: maxLength(5000)},
        alertHour: {},
        startHour: {
          required: requiredIf(() => this.notEmpty(this.row.drySaltTonnage) && this.empty(this.row.startHour) && this.empty(this.row.endHour) && this.empty(this.row.agentNumber))
        },
        endHour: {
          required: requiredIf(() => this.notEmpty(this.row.drySaltTonnage) && this.empty(this.row.startHour) && this.empty(this.row.endHour) && this.empty(this.row.agentNumber))
        },
        agentNumber: {},
        drySaltTonnage: {
          required: requiredIf(() => this.notEmpty(this.row.roadwayState) || this.notEmpty(this.row.snowState))
        },
        brineLiters: {},
        patrol: {},
        precurative: {},
        curative: {},
        scraping: {},
        singularPoint: {},
        obs2: {maxLength: maxLength(5000)},
        trafficCondition: {},
        obs3: {maxLength: maxLength(5000)},
      }
    }
  },
  methods: {
    copy() {
      this.$emit('copyRow', this.index);
    },
    paste() {
      this.$emit('pasteRow', this.index);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    checkAirTemp() {
      if (this.currentValue.airTemp < -100) this.currentValue.airTemp = -100;
      if (this.currentValue.airTemp > 100) this.currentValue.airTemp = 100;
    },
    roundAirTemp() {
      if (this.currentValue.airTemp) this.currentValue.airTemp = this.currentValue.airTemp.toFixed(2);
    },
    checkGroundTemp() {
      if (this.currentValue.groundTemp < -100) this.currentValue.groundTemp = -100;
      if (this.currentValue.groundTemp > 100) this.currentValue.groundTemp = 100;
    },
    roundGroundTemp() {
      if (this.currentValue.groundTemp) this.currentValue.groundTemp = this.currentValue.groundTemp.toFixed(2);
    },
    checkHumidity() {
      if (this.currentValue.humidity < 0) this.currentValue.humidity = 0;
      if (this.currentValue.humidity > 100) this.currentValue.humidity = 100;
    },
    roundHumidity() {
      if (this.currentValue.humidity) this.currentValue.humidity = this.currentValue.humidity.toFixed(2);
    },
    notEmpty(value) {
      return value !== null && value !== undefined && value !== '';
    },
    empty(value) {
      return value === null || value === undefined || value.replace(" ", "").length === 0;
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.row;
      }
    }
  }
}
</script>

<template>
  <Panel v-if="currentValue !== null && currentValue !== undefined" style="margin-bottom: 14px" class="col-sm">
    <template #header>Circuit n°{{ currentValue.circuitNumber }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ currentValue.circuitAxis}}</template>
    <template #icons v-if=!disabled>
      <button type="button" class="p-panel-header-icon p-link mr-2" @click="toggle($event)">
        <span class="pi pi-clone"></span>
      </button>
      <Menu id="config_menu" ref="menu" :model="items" :popup="true" />
    </template>
    
    <h5>{{ $t('link_sheet.road_weather') }}</h5>

    <div class="form-row">
      <AkCheckboxButton v-model="currentValue.roadwayState"
                        :disabled=disabled
                        :submitted=this.submitted
                        :options=roadwayStateConst
                        :label="$t('link_row.roadway_state')"
                        :validator="v$.row.roadwayState"
                        :multiple=false
                        class-name="col-md-12" />
    </div>
    <div class="form-row">
      <AkCheckboxButton v-model="currentValue.snowState"
                        :disabled=disabled
                        :submitted=this.submitted
                        :options=snowStateConst
                        :label="$t('link_row.snow_state')"
                        :validator="v$.row.snowState"
                        :multiple=false
                        class-name="col-md-12" />
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <AkLabel>{{ $t('link_sheet.air_temp') }}</AkLabel>
        <input v-model="currentValue.airTemp"
               :disabled=disabled
               :placeholder="$t('link_sheet.air_temp')"
               type="number"
               min="-100"
               max="100"
               step="0.1"
               @input="checkAirTemp"
               @focusout="roundAirTemp"
               class="form-control"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <AkLabel>{{ $t('link_sheet.ground_temp') }}</AkLabel>
        <input v-model="currentValue.groundTemp"
               :disabled=disabled
               :placeholder="$t('link_sheet.ground_temp')"
               type="number"
               min="-100"
               max="100"
               step="0.1"
               @input="checkGroundTemp"
               @focusout="roundGroundTemp"
               class="form-control"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <AkLabel>{{ $t('link_sheet.relative_humidity') }}</AkLabel>
        <input v-model="currentValue.humidity"
               :disabled=disabled
               :placeholder="$t('link_sheet.relative_humidity')"
               type="number"
               min="0"
               max="100"
               step="0.1"
               @input="checkHumidity"
               @focusout="roundHumidity"
               class="form-control"/>
      </div>
    </div>
    <div class="form-group-checkbox">
      <div class="form-row">
        <AkCheckbox v-model="currentValue.freezingHumidity"
                    :disabled=disabled
                    :submitted=this.submitted
                    :label="$t('link_sheet.freezing_humidity')"
                    :validator="v$.row.freezingHumidity"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.groundFrost"
                    :disabled=disabled
                    :submitted=this.submitted
                    :label="$t('link_sheet.ground_frost')"
                    :validator="v$.row.groundFrost"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.freezingFog"
                    :disabled=disabled
                    :submitted=this.submitted
                    :label="$t('link_sheet.freezing_fog')"
                    :validator="v$.row.freezingFog"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.rainOnFrozenGround"
                    :disabled=disabled
                    :submitted=this.submitted
                    :label="$t('link_sheet.rain_on_frozen_ground')"
                    :validator="v$.row.rainOnFrozenGround"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.rainOnSurfusion"
                    :disabled=disabled
                    :submitted=this.submitted
                    :label="$t('link_sheet.rain_on_surfusion')"
                    :validator="v$.row.rainOnSurfusion"
                    class-name="col-md-12"/>
      </div>
    </div>
    <div class="form-row">
      <AkInputTextArea v-if=!disabled
                       v-model="currentValue.obs1"
                       :disabled=disabled
                       :submitted=this.submitted
                       :label="$t('link_sheet.observations')"
                       :validator="v$.row.obs1"
                       :rows=getRowCount(currentValue.obs1)
                       class-name="col-md-12"/>
      <div v-else class="form-group col-md-12">
        <label class="fg-black">{{ $t('link_sheet.observations') }}</label>
        <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="currentValue.obs1"></div>
      </div>
    </div>

    <h5>{{ $t('link_sheet.treatment_strategy') }}</h5>

    <div class="form-row">
      <AkTime v-model="currentValue.alertHour"
              :disabled=disabled
              :label="$t('link_sheet.alert_hour')"
              :validator="v$.row.alertHour"
              class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkTime v-model="currentValue.startHour"
              :disabled=disabled
              :label="$t('link_sheet.start_hour')"
              :validator="v$.row.startHour"
              class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkTime v-model="currentValue.endHour"
              :disabled=disabled
              :label="$t('link_sheet.end_hour')"
              :validator="v$.row.endHour"
              class-name="col-md-12"/>
    </div>

    <div class="form-row">
      <AkInputNumber v-model="currentValue.agentNumber"
                     :disabled=disabled
                     :min="0"
                     :max="50"
                     :label="$t('link_sheet.agent_number')"
                     :validator="v$.row.agentNumber"
                     class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber v-model="currentValue.drySaltTonnage"
                     :disabled=disabled
                     :min="0"
                     :minFractionDigits=1
                     :maxFractionDigits=2
                     :label="$t('link_sheet.dry_salt_tonnage')"
                     :validator="v$.row.drySaltTonnage"
                     class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber v-model="currentValue.brineLiters"
                     :disabled=disabled
                     :min="0"
                     :minFractionDigits=1
                     :maxFractionDigits=2
                     :label="$t('link_sheet.brine_liters')"
                     :validator="v$.row.brineLiters"
                     class-name="col-md-12"/>
    </div>
    <div class="form-group-checkbox">
      <div class="form-row">
        <AkCheckbox v-model="currentValue.patrol"
                    :disabled=disabled
                    :label="$t('link_sheet.patrol')"
                    :validator="v$.row.patrol"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.precurative"
                    :disabled=disabled
                    :label="$t('link_sheet.precurative')"
                    :validator="v$.row.precurative"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.curative"
                    :disabled=disabled
                    :label="$t('link_sheet.curative')"
                    :validator="v$.row.curative"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.scraping"
                    :disabled=disabled
                    :label="$t('link_sheet.scraping')"
                    :validator="v$.row.scraping"
                    class-name="col-md-12"/>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="currentValue.singularPoint"
                    :disabled=disabled
                    :label="$t('link_sheet.singular_points')"
                    :validator="v$.row.singularPoint"
                    class-name="col-md-12"/>
      </div>
    </div>
    <div class="form-row">
      <AkInputTextArea v-if=!disabled
                       v-model="currentValue.obs2"
                       :disabled=disabled
                       :submitted=this.submitted
                       :label="$t('link_sheet.observations')"
                       :validator="v$.row.obs2"
                       :rows=getRowCount(currentValue.obs2)
                       class-name="col-md-12"/>
      <div v-else class="form-group col-md-12">
        <label class="fg-black">{{ $t('link_sheet.observations') }}</label>
        <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="currentValue.obs2"></div>
      </div>
    </div>

    <h5>{{ $t('link_sheet.traffic_conditions') }}</h5>
    
    <div class="form-row">
      <AkCheckboxButton v-model="currentValue.trafficCondition"
                        :disabled=disabled
                        :submitted=this.submitted
                        :options=trafficConditionConst
                        :label="$t('link_row.traffic_condition')"
                        :validator="v$.row.trafficCondition"
                        :multiple=false
                        class-name="col-md-12" />
    </div>
    <div class="form-row">
      <AkInputTextArea v-if=!disabled
                       v-model="currentValue.obs3"
                       :disabled=disabled
                       :submitted=this.submitted
                       :label="$t('link_sheet.observations')"
                       :validator="v$.row.obs3"
                       :rows=getRowCount(currentValue.obs3)
                       class-name="col-md-12"/>
      <div v-else class="form-group col-md-12">
        <label class="fg-black">{{ $t('link_sheet.observations') }}</label>
        <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="currentValue.obs3"></div>
      </div>
    </div>
  </Panel>
</template>

<style>
  .form-group-checkbox {
    padding: 19px 0 15px 0;
  }

  .form-group-checkbox .form-check {
    padding-bottom: 0;
    padding-top: 0;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
</style>