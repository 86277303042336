<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
  },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.id = "id_" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
  ,
  methods: {},
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
    ,
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    }
    ,
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid && this.alreadySubmitted;
    }
    ,
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
    ,
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <div class="form-check">
      <Checkbox :id="id" v-model="currentValue" :binary="true" class="form-check-input"
                value="{{this.label}}" :disabled="this.disabled"/>
      <AkLabel :id="id" :required="isRequired">{{ this.label }}</AkLabel>
      <div>
        <small
            v-if="isValidatorRequired"
            class="p-error">{{
            $t("field_required", [this.label])
          }}</small>
      </div>
    </div>
  </div>

</template>