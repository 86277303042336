<script>
import backMixin from "@mixins/backMixin";
import messageMixin from "@mixins/messageMixin";
import loaderMixin from "@mixins/loaderMixin";

export default {
  mixins: [backMixin, messageMixin, loaderMixin],
  props: {
    title: String,
    displayGoBack: {
      type: Boolean,
      required: false,
      default: true
    },
    classTitle: {
      type: String,
      required: false,
      default: "col-lg-6 col-8"
    }
    ,
    classAction: {
      type: String,
      required: false,
      default: "col-lg-6 col-4"
    }
  },
}
</script>

<template>
  <div>
    <Message v-for="msg of messages" :key="msg.content" :closable="msg.closable" :severity="msg.severity" @close="closeMessage(msg)">
      {{ msg.content }}
    </Message>
    <a v-if="displayGoBack" class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1"/>{{ $t('back') }}</a>
    <slot name="tabs"></slot>
    <div class="card card-statistics">
      <div class="card-header">
        <div class="card-heading">
          <div class="row align-items-center">
            <div :class="classTitle">
              <h5 class="card-title">{{ title }}</h5>
            </div>
            <div :class="classAction" class="text-right">
              <slot name="action"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body vld-parent">
        <slot name="form"/>
      </div>
    </div>
  </div>
  <slot name="extra"></slot>
</template>