import dateFormatter from "@mixins/dateFormatter";

export default {
  mixins:[dateFormatter],
  methods: {
    getSheetEvents(sheet) {
      let events = [];
      if (sheet.dateValidated) {
        events.push({
          name: this.$t("sheet.validated_by", [sheet.creatorFirstName + " " + sheet.creatorLastName]),
          dateDisplay: this.formatDateHour(sheet.dateValidated),
          date: sheet.dateValidated,
        });
      }
      events.push({
        name: this.$t("sheet.created_by", [sheet.creatorFirstName + " " + sheet.creatorLastName]),
        dateDisplay: this.formatDateHour(sheet.dateCreated),
        date: sheet.dateCreated
      });
        events.sort((a, b) => {
          return b.date.getTime() - a.date.getTime();
        });
        return events;
    }
  },
}